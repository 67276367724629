import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './css/new.css';
import './css/styles.css';
import './css/plugins.css';
import contact1 from './img/content/testimonial.jpg';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    phone: '',
    message: '',
    captcha: ''
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs.send(
      'service_5b9nrx6',    // Your Service ID
      'template_jv8r7jg',   // Your Template ID
      formData,
      'qYP0zmSOysBFBO16A'   // Your Public Key
    )
    .then((result) => {
      console.log('Email successfully sent:', result.text);
      alert('Your message has been sent!');
      setFormData({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: '',
        captcha: ''
      });
      setLoading(false);
    }, (error) => {
      console.log('Error in sending email:', error.text);
      alert('There was an error sending your message. Please try again later.');
      setLoading(false);
    });
  };
  return (
    <section className="p-0 overflow-visible">
      <div className="container-fluid px-0">
        <div className="row g-0 justify-content-center">
          <div className="col-lg-6">
            <div
              className="position-relative h-100 bg-img cover-background"
              style={{ backgroundImage: `url(${contact1})` }}
            ></div>
          </div>
          <div className="col-lg-6">
            <div className="bg-light h-100 p-2-2 p-lg-6 p-xl-8">
              <div className="section-title3 mb-1-9">
                <span className="sm-title">Contact Us</span>
                <h2 className="mb-0 h1">Get In Touch</h2>
              </div>
              <form className="contact quform" onSubmit={handleSubmit}>
                <div className="quform-elements">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="quform-element form-group">
                        <label htmlFor="name">Your Name <span className="quform-required">*</span></label>
                        <div className="quform-input">
                          <input
                            className="form-control"
                            id="name"
                            type="text"
                            name="name"
                            placeholder="Your name here"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="quform-element form-group">
                        <label htmlFor="email">Your Email <span className="quform-required">*</span></label>
                        <div className="quform-input">
                          <input
                            className="form-control"
                            id="email"
                            type="text"
                            name="email"
                            placeholder="Your email here"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="quform-element form-group">
                        <label htmlFor="subject">Your Subject <span className="quform-required">*</span></label>
                        <div className="quform-input">
                          <input
                            className="form-control"
                            id="subject"
                            type="text"
                            name="subject"
                            placeholder="Your subject here"
                            value={formData.subject}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="quform-element form-group">
                        <label htmlFor="phone">Contact Number</label>
                        <div className="quform-input">
                          <input
                            className="form-control"
                            id="phone"
                            type="text"
                            name="phone"
                            placeholder="Your phone here"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="quform-element form-group">
                        <label htmlFor="message">Message <span className="quform-required">*</span></label>
                        <div className="quform-input">
                          <textarea
                            className="form-control"
                            id="message"
                            name="message"
                            rows="3"
                            placeholder="Tell us a few words"
                            value={formData.message}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="quform-element">
                        <div className="form-group">
                          <div className="quform-input">
                            <input
                              className="form-control"
                              id="type_the_word"
                              type="text"
                              name="captcha"
                              placeholder="Type the below word"
                              value={formData.captcha}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="quform-captcha">
                            {/* <div className="quform-captcha-inner">
                              <img src="quform/images/captcha/courier-new-light.png" alt="Captcha" />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="quform-submit-inner">
                        <button className="butn-style1" type="submit">
                          <span>Send Message</span>
                        </button>
                      </div>
                      <div className="quform-loading-wrap text-start">
                        <span className="quform-loading"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
