import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './header.css';
import head1 from './imgpsh_fullsize_anim-removebg-preview.png';
import { FaSearch } from 'react-icons/fa';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  // Example search items with descriptions
  const items = [
    { id: 1, name: 'Home', link: '/home', keywords: ['homepage', 'welcome'], description: 'Welcome to Code Crafters HQ. We craft software solutions tailored to your business.' },
    { id: 2, name: 'Our Software Solutions', link: '/oursoftwaresolutions', keywords: ['solutions', 'software'], description: 'We offer custom software development, cloud solutions, integration services.' },
    { id: 3, name: 'Industries We Serve', link: '/Industriesweserve', keywords: ['industries', 'services'], description: 'Serving industries with unique software solutions and expertise.' },
    { id: 4, name: 'About Us', link: '/about', keywords: ['company', 'mission', 'about'], description: 'Learn more about our team of freelancers crafting solutions.' },
    { id: 5, name: 'Contact', link: '/contact', keywords: ['support', 'contact', 'help'], description: 'Get in touch with us for support or to start a project.' }
  ];

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Search function that handles name, keywords, and description
  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter items based on search query
    const results = items.filter(item =>
      item.name.toLowerCase().includes(query) ||
      item.description.toLowerCase().includes(query) ||
      item.keywords.some(keyword => keyword.includes(query))
    );

    setSearchResults(results);
  };

  return (
    <>
    <Helmet>
      <meta name="robots" content="index, follow" />
      <meta property="og:title" content="Custom Software Development Company | Tailored Software Solutions" />
      <meta property="og:description" content="We offer custom software development, web and mobile app development services tailored to your business needs. Get in touch with our experts to bring your ideas to life." />
      <meta property="og:image" content="URL-to-your-image" />
      <meta property="og:url" content="URL-to-your-website" />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Custom Software Development Company | Tailored Software Solutions" />
      <meta name="twitter:description" content="Specializing in custom software, web development, and mobile apps. Discover how our tailored solutions can help your business." />
      <meta name="twitter:image" content="URL-to-your-image" />
      <meta name="geo.region" content="US" />
      <meta name="geo.placename" content="City, State" />
      <meta name="geo.position" content="Latitude;Longitude" />
      <meta name="ICBM" content="Latitude, Longitude" />
      <link rel="icon" href={head1} type="image/png" />
    </Helmet>
  
      

    <header className="header">
      <div className="header-container">
      <div className={`logo ${menuOpen ? 'hide-logo' : ''}`}>
  <a href="/">
    <img 
      src={head1} 
      alt="About Us" 
      style={{ height: '50px', width: '100px', objectFit: 'cover' }} 
    />
  </a>
</div>

        <button className="navbar-toggler" onClick={toggleMenu}>
          ☰
        </button>

        <nav className={`menu ${menuOpen ? 'open' : ''}`}>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/oursoftwaresolutions">Solutions</Link></li>
            <li><Link to="/Industriesweserve">Industries</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/contact">Contact</Link></li>

          </ul>

          <div className="mobile-search">
            <div className="search-container">
              <input
                type="text"
                placeholder="Search..."
                className="search-bar"
                value={searchQuery}
                onChange={handleSearch}
              />
              <FaSearch className="search-icon" />
            </div>
            {searchQuery && (
              <div className="search-results">
                {searchResults.length > 0 ? (
                  <ul>
                    {searchResults.map(result => (
                      <li key={result.id}>
                        <Link to={result.link}>{result.name}</Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No results found</p>
                )}
              </div>
            )}
          </div>
        </nav>

        <div className="right-section">
          <div className="search-container">
            <input
              type="text"
              placeholder="Search..."
              className="search-bar"
              value={searchQuery}
              onChange={handleSearch}
            />
            <FaSearch className="search-icon" />
          </div>
          {searchQuery && (
            <div className="search-results">
              {searchResults.length > 0 ? (
                <ul>
                  {searchResults.map(result => (
                    <li key={result.id}>
                      <Link to={result.link}>{result.name}</Link>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No results found</p>
              )}
            </div>
          )}
        </div>
      </div>
    </header>
    </>
  );
};

export default Header;
