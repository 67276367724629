// src/pages/Home.js
import React from 'react';
import Slider from './slider'; // Import the TestimonialsSection component
import AboutUs from './AboutUs'; // Import the TestimonialsSection component
import Why from './why'; // Import the TestimonialsSection component
import { Helmet } from 'react-helmet';
import OurProcess from './ourprocess'; // Import the TestimonialsSection component

import ContactForm from './contact1'; // Import the TestimonialsSection component
import Services from './Services1'; // Import the TestimonialsSection component
import image4 from './img/banner/women-in-tech2-1.jpg';
const Home = () => {
  return (

    <div>
           <Helmet>
        <title>Custom Software Development Company | Tailored Software Solutions</title>
        <meta
          name="description"
          content="Top custom software development company offering tailored software solutions for businesses of all sizes. We specialize in bespoke software, web development, and mobile applications. Contact us to transform your ideas into innovative software."
        />
         <meta name="keywords" content="custom software development, bespoke software solutions, software development company, web development, mobile app development, software development services, technology solutions"></meta>
      </Helmet>
        {/* TESTIMONIALS */}
      <Slider /> {/* Add the TestimonialsSection here */}
         {/* TESTIMONIALS */}
         <AboutUs /> {/* Add the TestimonialsSection here */}
         {/* TESTIMONIALS */}
      < Services /> {/* Add the TestimonialsSection here */}
       {/* TESTIMONIALS */}
      <Why /> {/* Add the TestimonialsSection here */}
         {/* TESTIMONIALS */}
       
      {/* TESTIMONIALS */}
      < OurProcess /> {/* Add the TestimonialsSection here */}
       {/* TESTIMONIALS */}
      < ContactForm /> {/* Add the TestimonialsSection here */}
      {/* TESTIMONIALS */}
      <section className="hero-section"  style={{ marginTop:'17px' ,
  backgroundImage: `url(${image4})`,
    
  }}>
            <div className="overlay">
                <div className="content">
                    <h1 style={{ color: '#fff' }} >Ready to Get Started?
                    </h1>
                                        <p>Let’s bring your vision to life! Reach out to us today and see how Code Crafters HQ can help your 
                                        business succeed</p>
                    <a href="/contact" className="contact-button">Contact Us</a>
                </div>
            </div>
        </section>
     
    </div>
  );
};

export default Home;
