import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import about1 from './img/banner/page-title.jpg'; // Imported image

import about3 from './img/bg/CP2U_hdeMqlYOLI51hdwFfgVX58QIXnN0jsTsRBwEnuu0ldgnLrtfvZnMI0y2vtoeC62W11TdC8YY37udCL35gj8xpaB7vYC92iZgkJduu7HAgReucmzP4L1EgoUd8tVH9RosYvG0zfdH8T7D8pVO04.jpeg'

import Value from './Ourvalues'; // Import the TestimonialsSection component
import image4 from './img/banner/women-in-tech2-1.jpg';
import image5 from './img/bg/software-development-team.jpg'
import TestimonialsSection from './testimonialssection'; // Import the TestimonialsSection component
import TeamSection from './team'; // Import the TestimonialsSection component
import CounterSection from './counter'; // Import the TestimonialsSection component
import "./css/styles.css";

import "./css/plugins.css";
import "./search/search.css";
import "./quform/css/base.css";
import "./css/about.css";
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import co1 from './img/content/testimonial.jpg';
const About = () => {
  return (
    <div>
      {/* PAGE TITLE */}
     <section className="page-title-section bg-img cover-background mx-lg-1-6 mx-xl-2-5 mx-xxl-2-9 dark-overlay" data-overlay-dark="65" style={{ backgroundImage: `url(${about1})` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="position-relative">
                <h1 style={{ fontSize: '50px' }}>About Us</h1>
              </div>
              
            </div>
          </div>
        </div>
      </section>

      {/* ABOUT US */}
      <section className="about-style04">
        <div className="container">
          <div className="row mt-n1-9 align-items-xxl-center position-relative z-index-9">
            <div className="col-lg-6 mt-1-9 wow fadeInUp" data-wow-delay="100ms">
              <div className="about-image position-relative">
                 <img src={image5} alt="About Us"  style={{ height: '680px', width: '1000px', objectFit: 'cover' }} 
                 />
                
                
              </div>
            </div>
            <div className="col-lg-6 mt-1-9 wow fadeInUp" data-wow-delay="150ms">
              <div className="ps-lg-1-9 ps-xl-6 ps-xxl-9">
                <div className="section-title2 text-start mb-1-9">
                 
                  <h2 className="h1 mb-0" style={{ fontSize: '26px' }}>Who We Are</h2>
                </div>
                <p className="mb-2-2 mb-xxl-2-9" style={{ fontSize: '15px',marginTop: '-25px' }}>Welcome to Code Crafters HQ! We’re a group of nine passionate freelancers who came together with
a shared goal: to build custom software solutions that truly meet your needs. Each of us brings a
unique set of skills and experiences, allowing us to tackle a wide range of projects with creativity and
precision.
</p>
             <h2 className="h1 mb-0" style={{ fontSize: '26px', marginTop: '-15px' }}>Our Story</h2>
               
                <p className="mb-2-2 mb-xxl-2-9" style={{ fontSize: '15px',  marginTop: '5px' }}>Our journey began with a simple idea: exceptional software should be crafted to fit each client’s
unique needs. We united our diverse expertise to form a company that values collaboration,
innovation, and client satisfaction above all else.

</p>
 <h2 className="h1 mb-0" style={{ fontSize: '26px', marginTop: '-15px' }}>Our Mission</h2>
               
                <p className="mb-2-2 mb-xxl-2-9" style={{ fontSize: '15px',marginTop: '5px' }}>Our mission is clear: we’re here to turn your ideas into functional, high-quality software solutions.
We take the time to understand your business and your goals so that we can deliver software that
not only meets but exceeds your expectations. Quality, transparency, and a commitment to
excellence are at the heart of everything we do.


</p>
                
               
                <div className="pt-2-3 border-top border-color-extra-light-gray d-sm-flex align-items-center">
                  <div className="mb-4 mb-sm-0 me-sm-2-3">
                    <a href="/contact" className="butn-style2 medium"><span>Contact us</span></a>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 phone-icon me-3">
                      <FontAwesomeIcon icon={faPhone} />
                    </div>
                    <div className="flex-grow-1">
                      <p className="mb-0">Call us for help</p>
                      <h4 className="h5 mb-0">+919716640953</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ABOUT US */}
<section className="about-style04">
  <div className="container">
    <div className="row mt-n1-9 align-items-xxl-center position-relative z-index-9">
      <div className="col-lg-6 mt-1-9 order-lg-2 wow fadeInUp" data-wow-delay="100ms">
        <div className="about-image position-relative">
        <img src={about3} alt="About Us"  style={{ height: '700px', width: '800px', objectFit: 'cover' }} 
                 />
                
          
          
        </div>
      </div>
      <div className="col-lg-6 mt-1-9 order-lg-1 wow fadeInUp" data-wow-delay="150ms">
        <div className="ps-lg-1-9 ps-xl-6 ps-xxl-9">
          <div className="section-title2 text-start mb-1-9">
            <h2 className="h1 mb-0" style={{ fontSize: '33px' }}>Meet the Brains Behind the Magic</h2>
          </div>
          <p className="mb-2-2 mb-xxl-2-9" style={{ fontSize: '15px', marginTop: '-25px' }}>
            At Code Crafters HQ, it’s not just our core team of nine who’s making things happen. We have a
dedicated team of over 100 talented professionals working behind the scenes. These are the real
stars of our story, each bringing a wealth of experience and expertise to the table.
          </p>
          <h2 className="h1 mb-0"  style={{ fontSize: '24px',marginTop: '-16px' }}>Here’s what our incredible team is skilled in:</h2>

<ul style={{ listStyleType: 'disc', paddingLeft: '20px', fontSize: '15px',marginTop: '3px' }}>
  <li>Data Science: Transforming raw data into actionable insights to drive your business forward.</li>
  <li>Machine Learning: Developing algorithms that enable software to learn from and adapt to new data.</li>
  <li>Deep Learning: Utilizing advanced neural networks to tackle complex problems and achieve remarkable results.</li>
  <li>AI Ecosystem: Building and integrating cutting-edge artificial intelligence technologies to enhance your solutions.</li>
  <li>Blockchain: Crafting secure, transparent systems with blockchain technology to revolutionize your operations.</li>
</ul>
      <p className="mb-1-9 font-weight-600 lead wow fadeInUp" data-wow-delay="150ms" style={{ fontSize: '15px',marginTop: '-17px' }}>
           In addition to these areas, our team has extensive experience in a broad range of other technical
solutions, ensuring we can handle whatever challenge you bring to us
</p>
<p className="mb-1-9 font-weight-600 lead wow fadeInUp" data-wow-delay="150ms" style={{ fontSize: '15px', marginTop: '-17px', fontWeight: 'bold' }}>
   With such a diverse and skilled group behind us, we’re equipped to deliver innovative and effective
   solutions tailored to your needs.
</p>
<p className="mb-1-9 font-weight-600 lead wow fadeInUp" data-wow-delay="150ms" style={{ fontSize: '15px',marginTop: '-17px' }}>
           Whether you’re looking to leverage the latest in AI, explore the potential of blockchain, or solve
complex data challenges, our team is here to make it happen.

</p>
          
       
        </div>
      </div>
    </div>
  </div>
</section>


   {/* TESTIMONIALS */}
      <Value /> {/* Add the TestimonialsSection here */}
     
        <section className="hero-section"  style={{ 
  backgroundImage: `url(${image4})`,
    
  }}>
            <div className="overlay">
                <div className="content">
                    <h1 style={{ color: '#fff' }} >Join Us on Our Journey</h1>
                    <p>At Code Crafters HQ, we’re not just creating software; we’re crafting the future. Whether you need a new application, want to modernize an existing system, or need expert advice, we’re here to help. Let’s work together to turn your vision into reality!</p>
                    <p>Thank you for visiting our site. We’re excited about the possibility of working with you!</p>
                    <a href="/contact" className="contact-button">Contact Us</a>
                </div>
            </div>
        </section>
     
     
    
      
    </div>
  );
};

export default About;
