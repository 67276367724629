import React, { useState } from 'react';
import { FaPhoneAlt, FaMapMarkerAlt, FaEnvelope, FaSpinner } from 'react-icons/fa';
import emailjs from 'emailjs-com';
import "./css/styles.css";
import "./css/contact.css";
import "./css/plugins.css";
import "./search/search.css";
import "./quform/css/base.css";
import contact1 from './img/banner/page-title.jpg'; // Imported image
import contact2 from './img/content/contact-01.jpg'; // Imported image
import contact3 from './img/content/contact-02.jpg'; // Imported image
import contact4 from './img/content/contact-04.jpg'; // Imported image

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    phone: '',
    message: '',
    captcha: ''
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs.send(
      'service_5b9nrx6',    // Your Service ID
      'template_jv8r7jg',   // Your Template ID
      formData,
      'qYP0zmSOysBFBO16A'   // Your Public Key
    )
    .then((result) => {
      console.log('Email successfully sent:', result.text);
      alert('Your message has been sent!');
      setFormData({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: '',
        captcha: ''
      });
      setLoading(false);
    }, (error) => {
      console.log('Error in sending email:', error.text);
      alert('There was an error sending your message. Please try again later.');
      setLoading(false);
    });
  };
 
  return (
    <div>
      {/* Page Title Section */}
      <section className="page-title-section bg-img cover-background dark-overlay" style={{ backgroundImage: `url(${contact1})` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="position-relative">
                <h1 style={{ fontSize: '40px' }}>Contact Us</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Info Section */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="contact-info-block h-100">
                <div className="contact-img position-relative bg-img" style={{ backgroundImage: `url(${contact2})`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="bg-overlay"></div>
                  <div className="content-text" style={{ textAlign: 'center' }}>
                    <FaPhoneAlt className="icon" />
                    <h3 className="h4 mb-3">Phone</h3>
                    <p>+911203185939</p>
                    <p>   +1 754-209-3936</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="contact-info-block h-100">
                <div className="contact-img position-relative bg-img" style={{ backgroundImage: `url(${contact3})`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="bg-overlay"></div>
                  <div className="content-text" style={{ textAlign: 'center' }}>
                    <FaMapMarkerAlt className="icon" />
                    <h3 className="h4 mb-3">Location</h3>
                    <p>Niti Khand-1, Indirapuram, Ghaziabad-201014</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="contact-info-block h-100">
                <div className="contact-img position-relative bg-img" style={{ backgroundImage: `url(${contact4})`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="bg-overlay"></div>
                  <div className="content-text" style={{ textAlign: 'center' }}>
                    <FaEnvelope className="icon" />
                    <h3 className="h4 mb-3">Email</h3>
                    <p>sales@codecraftershq.com</p>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Form Section */}
      <section className="pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 mb-1-6 mb-xl-0">
              <div>
                <img src={contact4} alt="..." />
              </div>
            </div>
            <div className="col-lg-6">
            <div className="bg-light h-100 p-2-2 p-lg-6 p-xl-8">
              <div className="section-title3 mb-1-9">
                <span className="sm-title">Contact Us</span>
                <h2 className="mb-0 h1">Get In Touch</h2>
              </div>
              <form className="contact quform" onSubmit={handleSubmit}>
                <div className="quform-elements">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="quform-element form-group">
                      <label htmlFor="name">
                      Your Name <span className="quform-required">*</span>
                    </label>
                    <div className="quform-input">
                      <input
                        className="form-control"
                        id="name"
                        type="text"
                        name="name"
                        placeholder="Your name here"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="quform-element form-group">
                    <label htmlFor="email">
                      Your Email <span className="quform-required">*</span>
                    </label>
                    <div className="quform-input">
                      <input
                        className="form-control"
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Your email here"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="quform-element form-group">
                    <label htmlFor="subject">
                      Your Subject <span className="quform-required">*</span>
                    </label>
                    <div className="quform-input">
                      <input
                        className="form-control"
                        id="subject"
                        type="text"
                        name="subject"
                        placeholder="Your subject here"
                        value={formData.subject}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="quform-element form-group">
                    <label htmlFor="phone">Contact Number</label>
                    <div className="quform-input">
                      <input
                        className="form-control"
                        id="phone"
                        type="text"
                        name="phone"
                        placeholder="Your phone here"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="quform-element form-group">
                    <label htmlFor="message">
                      Message <span className="quform-required">*</span>
                    </label>
                    <div className="quform-input">
                      <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        rows="3"
                        placeholder="Tell us a few words"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="quform-element">
                    <div className="form-group">
                      <div className="quform-input">
                        <input
                          className="form-control"
                          id="type_the_word"
                          type="text"
                          name="captcha"
                          placeholder="Type the below word"
                          value={formData.captcha}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="quform-captcha">
                        {/* Add Captcha Image Here */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="quform-submit-inner">
                    <button className="butn-style1" type="submit">
                      <span>Send Message</span>
                    </button>
                  </div>
                      <div className="quform-loading-wrap text-start">
                        <span className="quform-loading"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
      
 </div>
        </div>
      </section>

      {/* Map Section */}
      <section className="p-0">
        <div className="container-fluid">
          <iframe
            className="contact-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7003.013116606449!2d77.35938264246558!3d28.64454750263228!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfab17227a035%3A0xbec22092fc244ba0!2sNiti%20Khand%20I%2C%20Indirapuram%2C%20Ghaziabad%2C%20Uttar%20Pradesh%20201014!5e0!3m2!1sen!2sin!4v1726506244431!5m2!1sen!2sin"
            title="Google Maps"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </section>
    </div>
  );
};

export default Contact;
